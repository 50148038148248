import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { Layout, LayoutProps } from './investors-n-partners-layout'
import Elevation from 'images/elevation.jpeg'
import Infoedge from 'images/infoedge-ventures.svg'
import EnterpreneurFirst from 'images/logo-ef.svg'
import HTDigitalHub from 'images/logo_5ht.svg'
import PlugNPlay from 'images/plug-and-play-logo-blue.svg'
import LuxResearch from 'images/logo_lux.svg'
import SPSJ from 'images/Polymer-science-logo.png'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const investors: LayoutProps['items'] = [
    { name: "Elevation", image: Elevation, link: 'https://elevationcapital.com/' },
    { name: "Infoedge", image: Infoedge, link: 'https://www.infoedgeventures.in/' },
    { name: "Enterpreneur First", image: EnterpreneurFirst, link: 'https://www.joinef.com/' },
]
const partners: LayoutProps['items'] = [
    { name: "5-HT Digitial Hub", image: HTDigitalHub, link: 'https://www.5-ht.com/en' },
    { name: "Plug and Play", image: PlugNPlay, link: 'https://www.plugandplaytechcenter.com/' },
    { name: "Lux Research", image: LuxResearch, link: 'https://www.luxresearchinc.com/' },
    { name: " Society of Polyemer Science", image: SPSJ, link: 'https://www.spsj.or.jp/index.html' },
]

export const InvestorsNPartners = () => {
    const { t } = useTranslation()

    return (
        <section className="flex flex-col gap-20 items-center text-center">
            <div className='flex flex-col gap-4 items-center'>
                <StaticImage
                    alt="illustration"
                    src="../../../images/icon_investors.svg"
                    className="sm:mx-auto"
                />
                <h2 className="tracking-wider text-gray-500 uppercase">
                    {t('about-us-page.investors-n-partners.label')}
                </h2>
                <p className="text-4xl tracking-tight sm:text-5xl max-w-3xl">
                    {t('about-us-page.investors-n-partners.heading')}
                </p>
            </div>

            <Layout title={t('common.words.investor')} items={investors} />
            <Layout title={t('common.words.partners')} items={partners} />
        </section>
    )
};
